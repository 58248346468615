@tailwind base;
@tailwind components;
@tailwind utilities;

@import '@vkontakte/vkui/dist/vkui.css';

:root {
  --transition-duration: 0.7s;
}

tr span {
  color: #132144 !important;
  font-size: 14px !important;
}

body {
  background-color: #f2f3f5;
  color: #fff;
  transition:
    background-color var(--transition-duration) ease,
    color var(--transition-duration) ease;
}

body.dark-mode {
  background-color: #141414;
}

body:has(.vkuiModalRoot) {
  overflow: hidden;
}

.custom-scrollbar::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #8c98a4;
  opacity: 0.8;
  border-radius: 12px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 12px;
}

.remove-delete-button {
  .vkuiChipsInputBase {
    > div:nth-child(2) {
      padding-right: 0.5rem;

      .vkuiChip__remove {
        display: none;
        visibility: hidden;
      }
    }
  }
}

.input-style > input {
  font-size: 15px;
  color: #818c99;
}

.filters-button {
  width: 120px;
}

.filters-button span {
  font-size: 15px;
  font-weight: 400;
}

.form-item {
  min-width: 248px;
}

.vkui-select .vkuiFormField {
  height: 40px !important;
}

.vkui-input input {
  height: 40px !important;
}

.filters-item input {
  height: 28px;
}

.vkui-edit-button-primary {
  height: 40px;
  padding: 0px 4px;
  border-radius: 4px;
  background-color: #5181b8 !important;

  > span {
    color: #ffffff;
  }
}

.vkui-edit-button-secondary {
  height: 40px;
  padding: 0px 4px;
  border-radius: 4px;
  background-color: #e5ebf1 !important;

  > span {
    color: #55677d;
  }
}

.vkui-edit-button-tertiary {
  height: 40px;
  padding: 0px 6px;
  border-radius: 4px;
  background-color: #ffffff !important;

  > span {
    color: #5181b8;
  }
}

.vkui-edit-button-error {
  height: 40px;
  padding: 0px 4px;
  border-radius: 4px;
  background-color: #e64646 !important;

  > span {
    color: #ffffff;
  }
}

.chips-select .vkuiFormField {
  width: 100%;
}

.chips-input {
  .vkuiFormField__border {
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid #ebebeb;
  }
}

.vkui-edit-pagination {
  > ul li {
    border-radius: 6px;
    border: 1px solid #ebebeb;
    margin: 0 4px;

    > div {
      border-radius: 5px;
    }

    &:first-child {
      margin-left: 0;
      margin-right: 8px;
    }

    &:last-child {
      margin-right: 0;
      margin-left: 8px;
    }
  }
}

.vkui-edit-date {
  height: 40px !important;
}

.vkui-edit-date-range-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 8px;
  border-radius: 8px;
  background-color: #f7f8fa;
  outline: 0.5px solid #d9dadc;
}

.vkui-edit-datetime-range-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 8px;
  border-radius: 8px;
  background-color: #f7f8fa;
  outline: 0.5px solid #d9dadc;
}

.vkui-edit-date-range {
  height: 40px !important;
  width: 120px;
  border: none !important;
  background-color: #f7f8fa;

  span {
    border: none !important;
  }
}

.vkui-edit-datetime-range {
  height: 40px !important;
  width: 170px;
  border: none !important;
  background-color: #f7f8fa;

  span {
    border: none !important;
  }
}

.text-editor {
  > div {
    &:first-child {
      border: 1px solid #e2e4e6;
      border-radius: 8px 8px 0 0;
      background-color: #f5f6f9;
    }

    &:last-child {
      border: 1px solid #e2e4e6;
      border-radius: 0 0 8px 8px;
      background-color: #f5f6f9;
    }
  }
}

.vkui-tabs {
  .vkuiPanelHeader__before,
  .vkuiInternalPanelHeader__before {
    display: none;
    visibility: hidden;
  }
}

.vkuiModalRoot__mask {
  position: fixed;
}

.vkuiPanelHeader__in {
  flex-grow: 1;
  min-width: 0;
}

.vkuiModalRoot .vkuiTappable__stateLayer {
  background: transparent !important;
}

.vkuiModalRoot .ReactCrop__child-wrapper img {
  max-height: 540px;
}

.vkuiModalPage__in-wrap {
  height: initial !important;
  max-height: initial !important;
}

.vkui-cell-default-cursor {
  cursor: default;

  > div {
    cursor: default;
  }
}

.vkui-datepicker {
  .vkuiDatePicker__day,
  .vkuiDatePicker__month {
    display: none;
  }
}

.scrollbar-hide {
  &::-webkit-scrollbar {
    display: none; 
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
}
